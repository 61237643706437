@font-face {
  src: url('fonts/MrsEavesXLSerifOT-Reg.eot');
  font-family: 'MrsEavesXLSerifOT-Reg';
  src: url('fonts/MrsEavesXLSerifOT-Reg.woff2') format('woff2'), url('fonts/MrsEavesXLSerifOT-Reg.woff') format('woff'),
    url('fonts/MrsEavesXLSerifOT-Reg.eot?#iefix') format('embedded-opentype'), url('fonts/MrsEavesXLSerifOT-Reg.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css?family=Roboto:wght@100;300;400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css?family=Nothing+You+Could+Do&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  border-collapse: collapse;
  outline: 0;
  text-decoration: none;
  box-sizing: border-box;
  font-feature-settings: 'liga' on, 'calt' on;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

a {
  color: white;
}

html {
  font-size: 12px;
}

body {
  background-color: white;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  font-size: 1rem;
  line-height: 1.25;
}

body * {
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
}

button {
  cursor: pointer;
}

code {
  font-family: 'JetBrains Mono';
}

input[type='checkbox'] {
  cursor: pointer;
}
